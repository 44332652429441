import React, { Component } from 'react';
import waiting from '../../../img/waiting.svg';
import okSymbol from '../../../img/ok-symbol.svg';
import ApiService from '../../../services/apiService';
import { postBase64, setLoadStatus } from '../../../scripts/functions';
import { connect } from 'react-redux';
import MyDropzone from '../../../utils/dropzone';
import pdfIcon from '../../../img/pdf-icon.png';
import MySkachat from './ico-skachat.jsx'
import { WELCOMEAPI } from "../../../scripts/indexphp"
import FatalError from '../../fatal'

class DonePage extends Component {

  state = {
    err:null,
    agentReward: '',
    PhoneNumber: '',
    Email: {
      approved: false,
      confirmLink: '',
      email: '',
    },
    Company: {
      companyId: "0acc6739-90de-4c02-9d20-d7369c505f5e",
      type: '',
      name: '',
      inn: '',
      phoneNumber: '',
      address: {
        legal: {},
        actual: {}
      }
    },
    CardRequest: [],
    Employee: {
      name: '',
      passportNumber: '',
      passportIssue: '',
      passportDate: '',
      passportCode: '',
    },
    Documents: [],
    documentsToRead: [],
    documentsToFill: [],
    flag: true,
    showLoader: false,
    clickedEmail: false,
    statusEmailSend: '',
  }

  _mounted = false;
  apiService = new ApiService();

  async componentDidMount() {
    this._mounted = true
    setLoadStatus(this.props.load, true);

    await this.getStatus();

    setLoadStatus(this.props.load, false);
  }

  componentWillUnmount() {
    this._mounted = false
  }

  getStatus =  () => {
    return this.apiService.getResource(`api/summarize/extended?companyId=${localStorage.getItem('companyId')}`)
      .then((res) => {
        if (!this._mounted) return false
        if (res.Documents) {
          this.setState({
            ...res,
            documentsToRead: res.Documents.filter(d => !d.needScan),
            documentsToFill: res.Documents.filter(d => d.needScan),
          })
        } else {
          this.setState({
            err: res,
          })
        }
        return false
      })
  }

  filesStateChanger = async (files, documentId) => {
    const index = this.state.documentsToFill.findIndex(d => d.documentId === documentId)
    if (index === -1) return false;

    this._mounted && await this.setState((state) => {
      state.documentsToFill[index].file = files;
      state.documentsToFill[index].format = files[0].type;
      state.documentsToFill[index].scanName = files[0].name;
      return state
    })

    this.uploadScan(this.state.documentsToFill[index], index)
  }

  uploadScan = async (doc) => {
    const data = {
      documentId: doc.documentId,
      document: await postBase64(doc.file),
      format: doc.format,
      name: doc.scanName,
    }
    await this.setState({ showLoader: true })
    this.apiService.getResource('api/document/uploadScan', 'POST', data)
      .then((res) => {
        this.getStatus()
      })
      .finally(() => {
        this.setState({ showLoader: false })
      })
  }

  getEmail = () => {
    let emailConfirmLink = this.state.Email.confirmLink
    emailConfirmLink = emailConfirmLink.split('/api/')[1]

    return this.apiService.getResource(`api/${emailConfirmLink}`, 'GET')
      .then(async (res) => {
        if (res.success) {
          this.setState({
            clickedEmail: true,
            isEmailSend: ''
          })
        } else {
          // this.setState({ isEmailSend: res.message })
          this.setState({ statusEmailSend: 'Отправка не удалась. ' })
        }
      })
  }
  render() {
    const { err, PhoneNumber, Email, clickedEmail, statusEmailSend, CardRequest, Documents, documentsToRead, documentsToFill, Employee, Company: { name = '', inn = '', address = '', type, correspondentAccount = '', SettlementAccount = '', bik = '', CompanyInfoIsCorrectly, bankRequisiteIsCorrectly } } = this.state;
    const noDocuments = !Documents.length
    const noDocumentsToFill = !documentsToFill.length

    if (err) {
      return ( <FatalError err={err} />  )
    }

    const items = [
      {
        title: (<>Телефон <b>{PhoneNumber}</b> подтвержден</>),
        content: false,
        status: true
      },
      {
        title: (<>
          E-mail <b>{Email.email}</b> {Email.approved === false ? 'не' : ''} подтвержден
          {Email.approved === false ?
            <button className={`link-email${clickedEmail ? " disabled" : ""}`} target="_blank" rel="noreferrer" onClick={!clickedEmail ? this.getEmail : null}>
              <span>
                {statusEmailSend.length ? statusEmailSend : ''}
              </span>
              {!clickedEmail ? 'Отправить повторно' : 'Письмо отправлено'}
            </button>
            : ''
          }
        </>),
        content: false,
        status: Email.approved
      },
      {
        title: `Данные ${type !== 'Freelance' ? 'об организации' : 'о фрилансере'} заполнены и формально корректны`,
        content: (<>
          <div className="title-div">
            {type !== 'Freelance' ? `${name}, ИНН: ${inn}` : `ИНН: ${inn}`}
          </div>
          <div className="text-div">{address ? `${address.zipCode}, ${address.city}, ${address.street}` : null}</div>
        </>),
        status: CompanyInfoIsCorrectly
      },
      {
        title: type !== 'Freelance' ? 'Данные о руководителе организации заполнены' : 'Паспортные данные заполнены',
        content: (<>
          <div className="title-div">{Employee.name}</div>
          <div className="text-div">Паспорт {Employee.passportNumber}, выдан {Employee.passportIssue}, {Employee.passportDate}, код подразделения {Employee.passportCode}</div>
        </>),
        status: Employee.employeeIsCorrectly
      },
      {
        title: 'Реквизиты организации заполнены и проверены',
        content: (<>
          <div className="title-div">Р/сч. {SettlementAccount}</div>
          <div className="text-div">{''} <br /> БИК {bik}, К/сч {correspondentAccount}</div>
        </>),
        status: bankRequisiteIsCorrectly,
        hide: type === 'Freelance'
      },
      {
        title: 'Запросы на карты самоинкассации',
        content: CardRequest.map((r, i) => <div key={i} className="request-row">
          <div className="title-div">{r.name}</div>
          <div className={`status-div ${r.status === "Не обработан" ? 'waiting' : 'done'}`}>{r.status}</div>
          <div className="text-div">Карта {r.type === "Sberbank" ? 'Сбербанк' : 'Альфа-банк'}</div>
        </div>),
        status: false,
        hide: type === 'Freelance'
      },
      // {
      //   title: <>Способ получения выплат: {agentReward}</>,
      //   content: false,
      //   status: false
      // },
    ];

    const DataItem = ({ title, status, content, hide }) => !hide && (

      <div className="data-item">
        <div className="data-item-inner">
          <div className={`image-div ${status ? 'done' : 'waiting'}`}><img alt="" src={status ? okSymbol : waiting} /></div>
        </div>

        <div className="data-item-content">
          <div className="data-item-inner"><div className="data-item-header">{hide} {title}</div></div>
          {content && <div className="data-item-body">{content}</div>}
        </div>
      </div>
    );

    return (
      <div className="done-page">

        <div className="done-page-inner">

          <h2>{noDocuments ? 'Спасибо, данные внесены' : 'Регистрационные данные'}</h2>

          <div className="text-div">{noDocuments ? 'Мы проверим вашу анкету, пришлем реквизиты доступа, а также добавим на эту страницу документы, которые неоходимо будет подписать' : 'Ваша анкета проверена, для полного доступа к платформе вам необходимо подписать прикрепленные документы'}</div>

          <div className="data-list">
            {/* {items.map((i, index) => <DataItem key={index} title={i.title} content={i.content} status={i.status} hide={type === "Freelance" && (index === 3 || index === 5) ? true : false} />)} */}
            {items.map((i, index) => <DataItem key={index} title={i.title} content={i.content} status={i.status} hide={i.hide} />)}
          </div>

          <h2>Документы</h2>
          <div className="text-div">{!Documents.length ? 'Пока документов нет, они появятся после проверки вашей анкеты. Мы сообщим вам об этом' : documentsToFill.length ? 'Нам требуются подписанные сканы некоторых документов. Скачайте их, подпишите и загрузите сканы в форму' : null}</div>

          <div className={`documents-container ${noDocumentsToFill ? 'only-documents-to-read' : ''}`}>
            {documentsToFill.length ? <div className="documents-list">
              {documentsToFill.map((d) => (
                <div className="done-page-document" key={d.documentId}>
                  <a className="done-page-document-column" href={WELCOMEAPI + d.link} download={d.fileName}>
                    <img src={pdfIcon} alt="pdf" />
                  </a>
                  <div className="done-page-document-column">
                    <a className='download-file-btn' href={WELCOMEAPI + d.link} download={d.fileName}><MySkachat /> {d.fileName}</a>
                    <MyDropzone
                      dropzoneInputId={d.documentId}
                      photo={d.file}
                      showLoader={this.state.showLoader}
                      filesStateChanger={this.filesStateChanger}
                      content={!this.state.showLoader ? d.scanLink ? (
                        <a style={{ display: 'flex', columnGap: '8px', justifyContent: 'center', alignItems: 'center', width: '100%' }} href={WELCOMEAPI + d.scanLink} download={d.scanName} onClick={(e) => e.stopPropagation()}>
                          <div className={`image-div done`}><img alt="ok" src={okSymbol} /></div>
                          <div style={{ color: '#2FBA8C', fontWeight: 500 }}>Скан успешно загружен</div>
                        </a>
                      ) : <div style={{ display: 'flex', columnGap: '8px', justifyContent: 'center', alignItems: 'center', width: '100%' }}>Загрузить скан документа</div> : false}
                      altContent={d.scanLink}
                    />
                  </div>
                </div>
              ))}
            </div> : null}

            {documentsToFill.length && documentsToRead.length ? (
              <h3>Осуществлен акцепт следующих документов:</h3>
            ) : null}

            <div className="documents-list">
              {documentsToRead.map((d) => (
                <div className="done-page-document" key={d.documentId}>
                  <div className="done-page-document-column">
                    <img src={pdfIcon} alt="pdf" />
                  </div>
                  <div className="done-page-document-column">
                    <a className='download-file-btn' href={WELCOMEAPI + d.link} download={d.fileName}><MySkachat /> {d.fileName}</a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    state: state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    load: (bool) => {
      return dispatch({ type: 'LOAD_CHANGE', payload: bool })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DonePage);