// получаем свойства заданные на страничке в php файлике


export const AGREE_TEXT=get('AGREE_TEXT')
export const WELCOMEAPI=get('WELCOMEAPI','/welcomeapi')
export const DADATA=get('DADATA')
export const IS_FREELANCE=get('FREELANCE',false)
export const IS_WELCOME=!IS_FREELANCE
export const SIDE_PANEL=get('SIDE_PANEL','<div class="side-panel">side-panel</div>')

function get(n, def) {
    const d= def===undefined ? n : def
    let w=window.APP
    if (w) {
        w=w[n];
    }
    return w===undefined ? d : w
}
