
function validateInn(inn) {
  var error = {code:-1, message: ''}
  var result = false;
  if (typeof inn === 'number') {
    inn = inn.toString();
  } else if (typeof inn !== 'string') {
    inn = '';
  }
  if (!inn.length) {
    error.code = 1;
    error.message = 'ИНН пуст';
  } else if (/[^0-9]/.test(inn)) {
    error.code = 2;
    error.message = 'ИНН может состоять только из цифр';
  } else if ('00' === inn.substr(0,2)) {
    error.code = 4;
    error.message = 'ИНН не может начинаться с 00';
  } else if ([12].indexOf(inn.length) === -1) {
    error.code = 3;
    error.message = 'ИНН может состоять только из 12 цифр';
  } else {
    var checkDigit = function (inn, coefficients) {
      var n = 0;
      for (var i in coefficients) {
        n += coefficients[i] * inn[i];
      }
      return parseInt(n % 11 % 10);
    };
    switch ( inn.length ) {
      case 10:
        var n10 = checkDigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8]);
        if (n10 === parseInt(inn[9])) {
          result = true;
        }
        break;
      case 12:
        var n11 = checkDigit(inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
        var n12 = checkDigit(inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
        if ((n11 === parseInt(inn[10])) && (n12 === parseInt(inn[11]))) {
          result = true;
        }
        break;
      default: 
      break;
    }
    if (!result) {
      error.code = 4;
      error.message = 'Неправильное контрольное число';
    }
  }
  return {ok:result, message: error.message}
}

export {validateInn} 