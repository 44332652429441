// компонент первого шага расширенной регистрации

import React, { Component } from "react";
import { connect } from "react-redux";

import { ValidFuncConsumer } from "../../../scripts/validFuncContext";
import ApiService from "../../../services/apiService";
import MyInput from "../../../utils/input";
import history from "../../../scripts/history";
import CheckboxList from "../../../utils/checkbox";
import { isEquivalent, phoneNumberCutter, } from "../../../scripts/functions";
import {IS_FREELANCE} from "../../../scripts/indexphp.js"
import {validateInn} from "../../../utils/inn-validate.js"


const ALL_YURIK_NAMES = [
  ['LegalEntity','Юридическое лицо'],
  ['Individual','Индивидуальный предприниматель'],
  ['Freelance','Фрилансер'],
]  


class RegStepFirst extends Component {

  options_show = IS_FREELANCE ? ALL_YURIK_NAMES.filter( x => x[0]==='Freelance' ) 
    : ALL_YURIK_NAMES.filter( x => x[0]!=='Freelance' )

  optionsCheckINN = {
    LEGAL: 'LegalEntity',
    INDIVIDUAL: 'Individual',
    FREELANCE: 'Freelance',
  }

  state = {
    changed: false,
    inn: '',
    innIsCorrect: false,
    prevType: null,
    institutionType: '',
    agree: true,
    legalAddress: {},
    companyPhone: '',
    companyName: '',
    ceo_name: '',
    ceo_post: '',
    zipCode: '',
    city: '',
    street: '',
    toCreate: localStorage.getItem('companyId') ? false : true,
    innerr: '',
  };

  stateCopy = {};

  apiService = new ApiService();
  _mounted = false

  async componentDidMount() {
    this._mounted = true
    this.props.updateCurStep(0);
    const companyId = localStorage.getItem('companyId');
    if (companyId) {
      this.getData(companyId);
    }
    if (IS_FREELANCE) {
      this.checkboxHandler(0)
    }
  };

  componentWillUnmount() {
    this._mounted = false
  }

  get_inn_err = (x) => {
    let {ok,message} = validateInn(x)
console.log('get_inn_err', 'x', x,message)    
    return ok ? '' : message
  }

  update_inn = (v, setInnIsCorrect=false ) => {
    let inn = v.replace(/[^0-9]/g,'')
    let nov = {
      inn,
      changed: true
    }
    if (this.state.institutionType === 'Freelance') {
      let innerr = this.get_inn_err(inn)
      nov = { ...nov, innerr }
    }
    if (setInnIsCorrect) {
      nov = { ...nov, innIsCorrect: false }
    }
//console.log('update_inn', inn, nov.innerr )    
    return this.setState(nov)
  }

  checkInn = async (checkValidity) => {
    this.setState({innerr:''})
    if (checkValidity && checkValidity('reg-step-first-form')) return false
    this.props.load(true)
    return await this.apiService.getResource('api/companyInfo/getCompanyInfo', 'POST', { inn: this.state.inn.replaceAll(' ', '') })
      .then(async (res) => {
        this.props.load(false)

        if (!this._mounted) return false


        if (res.success) {
          const { data } = res;
          if (data.isActive) {
            const type = this.optionsCheckINN[res.data.type]
            this.setState({
              institutionType: type || 'Freelance',
              innIsCorrect: data.inn,
              legalAddress: {
                city: data.city,
                zipCode: data.postal_code,
                street: data.street,
              },
              companyName: data.name,
              ceo_name: data.ceo_name,
              ceo_post: data.ceo_post,
            })
          } else {
            let innerr = `Организация ${data.name} ИНН ${data.inn} ликвидирована. Вы можете зарегистрироваться и работать с нами как фрилансер.`
            this.setState({innerr})
          }
        } else {
          let innerr=res.message || 'не удалось!'
          this.setState({innerr})
        }

        return true
      })
      .catch((error) => {
        console.error('checkInn', error)
      })
  };

  getData = (companyId) => {
    this.props.load(true);
    return this.apiService.getResource(`api/companyInfo?companyId=${companyId}`, "GET", {})
      .then((res) => {
        this.props.load(false);

        if (res && res.success) {
          let innerr = res.data.type === 'Freelance' ? this.get_inn_err(res.data.inn) : ''
          const gettedData = {
            zipCode: res.data.address.legal.zipCode,
            city: res.data.address.legal.city,
            street: res.data.address.legal.street,
            toCreate: false,
            innIsCorrect: res.data.inn || '',
            institutionType: res.data.type,
            inn: res.data.inn || '',
            companyPhone: res.data.phoneNumber || '',
            agree: isEquivalent(res.data.address.legal, res.data.address.actual),
            prevType: res.data.type,
            innerr,
          }
          this.stateCopy = gettedData;
          this.setState({ ...gettedData })
          if (res.data.type !== 'Freelance') {
            this.checkInn()
          }
        }

        return true
      }).catch( (err) => {
        this.props.load(false)
        console.error('getData', err)
      })
  };

  onSubmit = (e) => {
    e.preventDefault();
  };

  toTheNextStep = async (checkValidity) => {
    const { state } = this;
    if (checkValidity("reg-step-first-form")) {
      return false
    }
    if ( this.state.innerr ) {
      return false
    }
    if (this.props.state.load) {
      return false;
    }
    this.props.load(true);
    if (!state.changed) {
      this.props.updateDoneStep(1);
      history.push(`/registration/step-2`);
      this.props.load(false);
      return false
    }

    const legalAddress = state.legalAddress;

    const actualAddress = state.agree ? legalAddress : {
      zipCode: state.zipCode,
      city: state.city,
      street: state.street
    };

    await this.apiService.getResource('api/companyInfo', state.toCreate ? 'POST' : 'PUT', state.institutionType !== 'Freelance' ? {
      type: state.institutionType,
      inn: state.innIsCorrect.replaceAll(' ', '') || state.inn.replaceAll(' ', ''),
      name: state.companyName,
      phoneNumber: state.companyPhone.replaceAll(' ', ''),
      address: {
        legal: legalAddress,
        Actual: actualAddress
      },
      companyId: localStorage.getItem('companyId')
    } : {
      type: state.institutionType,
      companyId: localStorage.getItem('companyId'),
      inn: state.inn,
      phoneNumber: state.toCreate ? localStorage.getItem('phoneNumber') : state.companyPhone
    })
      .then(async (res) => {
        this.props.load(false);
        // console.log(res)
        if (!res.success) {
          let innerr=res.message || 'не удалось!'
          this.setState({innerr})
          return false;
        }

        this.props.updateDoneStep(1);
        localStorage.setItem('companyId', res.data.companyId)
        history.push(`/registration/step-2`);
      }).catch( (err) => {
        this.props.load(false);
        console.error('toTheNextStep', err)
        let innerr='не удалось! (toTheNextStep)'
        this.setState({innerr})
        return false;
      } )
  }

  checkboxHandler = (index) => {
    if (this.state.prevType === index) {
      this.setState({
        ...this.stateCopy,
        institutionType: this.options_show[index][0],
        changed: false
      })
    } else {
      this.setState({
        institutionType: this.options_show[index][0],
        innIsCorrect: false,
        inn: '',
        changed: true
      })
    }
  }

  render() {
    const { companyPhone, innIsCorrect, inn, institutionType, agree, companyName, ceo_name, ceo_post, legalAddress, zipCode, city, street, innerr, } = this.state;

    return (
      <ValidFuncConsumer>
        {({ checkValidity }) =>
          <form noValidate id="reg-step-first-form" className="reg-step-form" onSubmit={this.onSubmit}>

          {!IS_FREELANCE && (
            <CheckboxList className="institution-types" count={this.options_show.findIndex( x => x[0] === institutionType)} text={this.options_show.map( x => x[1])} handler={this.checkboxHandler} />
          )}

          {IS_FREELANCE && (
            <div>Фрилансер</div>
          )}


            <div className="row-of-inputs without-media">
              <MyInput title="Введите ИНН" id="inn" value={inn} onChange={(e) => this.update_inn(e.target.value)} required />
              { innerr ? <div className="redgvn">{innerr}</div> : null }
              <button type="button" className="primary-text return-to-initial" onClick={() => this.update_inn('', true)}>Очистить</button>
            </div>

            {innIsCorrect && institutionType !== 'Freelance' ? <>
              <div className="info-block">
                <div className="title-div">{companyName}</div>
                <div className="text-div">{legalAddress.zipCode}, {legalAddress.city}, {legalAddress.street} <br /> {ceo_post && ceo_name ? `${ceo_post} — ${ceo_name}` : null}</div>
                <div className="additional-notice">{`Данные об организации получены автоматически из ${institutionType === 'Individual' ? 'ЕГРЮЛ' : 'ЕГРИП'} ИФНС России на основании введенного ИНН`}</div>
              </div>

              <MyInput title="Телефон организации" autoComplete="tel" id="company-phone" name="company-phone" messagetext="Введите номер в формате 7ХХХХХХХХХХ" value={companyPhone} onInput={(e) => this.setState({ companyPhone: phoneNumberCutter(e.target.value), changed: true })} pattern="^(\+7|7|8)[0-9]{3}[0-9]{3}[0-9]{2}[0-9]{2}$" placeholder="7ХХХХХХХХХХ" required />

              <h4>Фактический адрес</h4>
              <div className="agree-with-container" onClick={() => this.setState({ agree: !this.state.agree, changed: true })}>
                <div className={agree ? "app-checkbox active" : 'app-checkbox'}></div>
                <div className="agree-with">Совпадает с юридическим</div>
              </div>

              {!agree ? <>
                <div className="row-of-inputs first-step">
                  <MyInput title="Индекс" name="point-id" messagetext="Некорректный ввод" value={zipCode} onInput={(e) => this.setState({ zipCode: e.target.value, changed: true })} pattern="^[0-9]{6}$" placeholder="XХХХХХ" required />

                  <MyInput title="Город, регион" name="city-region" value={city} onInput={(e) => this.setState({ city: e.target.value, changed: true })} required />
                </div>

                <MyInput title="Улица, квартира/офис" name="address" value={street} onInput={(e) => this.setState({ street: e.target.value, changed: true })} required />
              </> : null}

            </> : null}

            <div className="btns-container">
              <button type="button" id="app-btn" className="primary-btn app-btn" onClick={() => innIsCorrect || institutionType === 'Freelance' ? this.toTheNextStep(checkValidity) : this.checkInn(checkValidity)}>Продолжить</button>
            </div>
          </form>
        }
      </ValidFuncConsumer>
    )
  }
};

const mapStateToProps = (state) => {
  return {
    state: state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    load: (bool) => {
      return dispatch({ type: 'LOAD_CHANGE', payload: bool })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegStepFirst);