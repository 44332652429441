// Компонент входа/первичной регистрации

import React from "react"
import {SIDE_PANEL} from "../../../scripts/indexphp.js"

const SidePanel = () => {
  return (
    <div className="side-panel" dangerouslySetInnerHTML={{ __html: SIDE_PANEL }} />
  )
}

export default SidePanel
