import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { ValidFuncConsumer } from "../../../scripts/validFuncContext";

import ApiService from "../../../services/apiService";
import { addMessage, scrollPageTo, setLoadStatus, getFromBase64, postBase64, phoneNumberCutter, showErrorMessage } from "../../../scripts/functions";
import MyInput from "../../../utils/input";
import MyDropzone from "../../../utils/dropzone";
import history from "../../../scripts/history";
import Popup from '../../../utils/popup';
import IMask from 'imask';
import MyOtp from "../../../utils/my-otp";
import { AddressSuggestions } from 'react-dadata';

class RegStepThird extends Component {

  state = {
    otpValue: '',
    time: null,
    type: '',
    agree: true,
    fullName: '',
    phoneNumber: '',
    email: '',
    birthDay: '',
    birthPlace: '',
    passportNumber: '',
    passportDate: '',
    passportCode: '',
    passportIssue: '',
    address: '',
    files: [null, null, null,
      // null 
    ],
    filesFormat: {
      passport1stPageFormat: '',
      passportRegistrationPageFormat: '',
      passportEarlyPageFormat: '',
      // photoFormat: ''
    },
    toCreate: true,
    changed: false,
    popupOpened: false,
    isPhoneNumberValid: '',
    canGet: false,
    validPhoneNumber: null,
    loaded: false
  }

  dadataUrl = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address"
  dadataToken = '98f740f9f17576e648148d5056c68fca0e65320b'
  handleAddressChange = (event) => {
    this.address = this.postalCode ? (this.postalCode + ', ' + event.target.value) : event.target.value
    // console.log(this.address);
  }

  _mounted = false;
  apiService = new ApiService();
  filesDesc = ['Первая страница', 'Страница регистрации', '19 страница паспорта']

  async componentDidMount() {
    this._mounted = true
    this.props.updateCurStep(2);
    await this.getCompanyInfo();
    if (this.state.type !== 'Freelance') this.getManagerData();

    this.loaded = true;
  };

  onSubmit = (e) => {
    e.preventDefault();
  };

  setMask = () => {
    this.birthDay = IMask(document.getElementById('birthDay'), { mask: '00.00.0000', lazy: true });
    this.passportNumber = IMask(document.getElementById('passportNumber'), { mask: '0000 000000', lazy: true });
    this.passportDate = IMask(document.getElementById('passportDate'), { mask: '00.00.0000', lazy: true });
    this.passportCode = IMask(document.getElementById('passportCode'), { mask: '000-000', lazy: true });
  };

  getCompanyInfo = async () => {
    return await this.apiService.getResource(`api/companyInfo?companyId=${localStorage.getItem('companyId')}`, "GET", {})
      .then(async (res) => {
        // console.log(res)
        if (!res || !res.success) return false

        this._mounted && this.setState({ type: res.data.type })
        return true
      })
  }

  getManagerData = async () => {
    return await this.apiService.getResource('api/employee/getEmployees', "POST", { companyId: localStorage.getItem('companyId') })
      .then(async (res) => {
        // console.log(res)

        let person;
        if (res.data) person = res.data.filter(p => p.role === 'Manager');
        person = person ? person[0] : false;
        if (!person) return false

        return await this.apiService.getResource(`api/employee?userId=${person.userId}`, 'GET', {})
          .then(async (result) => {
            // console.log(result)
            if (!result || !result.success || !result.data) return false

            this.setState({
              ...result.data,
              validPhoneNumber: result.data.phoneNumber,
              isPhoneNumberConfirmed: true,
              fullName: result.data.name,
              toCreate: false
            })

            const transformFiles = (files = [], names = [], formats = []) => files.forEach((f, i) => this.getBase64(f, names[i], formats[i]))
            transformFiles(
              [result.data.passport1stPage, result.data.passportRegistrationPage, result.data.passportEarlyPage,
                //  result.data.photo 
              ],
              ['passport1stPage', 'passportRegistrationPage', 'passportEarlyPage',
                //  'photo'
              ],
              [result.data['passport1stPageFormat'], result.data['passportRegistrationPageFormat'], result.data['passportEarlyPageFormat'],
                // result.data['photoFormat']
              ]
            )

            this.setState({ agree: false, })
            return true
          })
      })
  }

  toTheNextStep = async (checkValidity) => {
    if (this.props.state.load) return false;
    await setLoadStatus(this.props.load, true);
    const a = this.state.agree || !this.state.changed || await this.sendManagerData(checkValidity);
    console.log(a)

    if (a) {
      this.props.updateDoneStep(3);
      history.push('/registration/step-4')
    }
    await setLoadStatus(this.props.load, false);
  }

  sendManagerData = async (checkValidity) => {
    if (checkValidity('reg-step-third-form')) {
      return false
    }

    if (this.state.validPhoneNumber !== this.state.phoneNumber) {
      addMessage('#phone-number', 'Неподтвержденный номер', true);
      scrollPageTo(document.getElementById('phone-number'))
      return false
    }

    const data = {
      role: 1,
      companyId: localStorage.getItem('companyId'),
      name: this.state.fullName,
      phoneNumber: this.state.phoneNumber,
      email: this.state.email,
      birthDay: this.state.birthDay,
      birthPlace: this.state.birthPlace,
      passportNumber: this.state.passportNumber,
      passportDate: this.state.passportDate,
      passportCode: this.state.passportCode,
      passportIssue: this.state.passportIssue,
      address: this.state.address,
    };

    const files = this.state.files;
    const names = [
      'passport1stPage',
      'passportRegistrationPage',
      'passportEarlyPage',
      // 'photo'
    ];

    let checkFilesValid = true;
    const filesLength = files.map(file => file !== null ? Object.keys(file).length : false);

    files.forEach((f, i) => {
      if (!filesLength[i]) {
        checkFilesValid = false
      }
    })
    if (checkFilesValid && files.length) {
      for (let i = 0; i < files.length; i++) {
        data[names[i]] = await postBase64(files[i], names[i], this.onload);
        data[names[i] + "Format"] = this.state.filesFormat[names[i] + "Format"]
      }
    } else {
      this._mounted && addMessage('.my-dropzone-row', 'Необходимо добавить все фото');
      return false
    }

    return await this.apiService.getResource('api/employee', this.state.toCreate ? 'POST' : 'PUT', data)
      .then(async (res) => {
        if (res && !res.success) {
          if (res.errorField) {
            addMessage(`#${res.errorField}`, res.message, true)
            scrollPageTo(document.querySelector(`#${res.errorField}`))
          }
          showErrorMessage(res.message)
          return false
        }
        return res && res.success
      })
  }

  skipStage = () => {
    this.apiService.getResource('api/registration/skipStage', 'POST', {
      companyId: localStorage.getItem('companyId'),
      stage: 4
    })
      .then((res) => {
        // console.log(res)
        this.props.updateDoneStep(3);
        history.push('/registration/step-4')
      })
  }

  filesStateChanger = (files, fileNumber) => {
    this.setState((state) => {
      state.files[fileNumber] = files
      state.changed = true
      return state
    })
  };

  getBase64 = (imageBase64, filename, type) => {
    if (!imageBase64) return false
    const ruNamesSet = {
      passport1stPage: 'Первая страница',
      passportRegistrationPage: 'Страница регистрации',
      passportEarlyPage: 'Ранее выданные паспорта',
      // photo: 'Фото с паспортом',
    }
    const namesId = {
      passport1stPage: 0,
      passportRegistrationPage: 1,
      passportEarlyPage: 2,
      // photo: 3,
    }

    const file = getFromBase64(imageBase64, filename, type, ruNamesSet)

    this.setState((state) => {
      state.files[namesId[filename]] = [file]
      return state
    })
  };

  onload = (file, name) => {
    return this.setState((state) => {
      state.filesFormat[name + "Format"] = file[0].type;
      return state
    })
  }

  onChangeOtpInput = async (otp) => {
    const otpText = String(otp)[String(otp).length - 1];
    if (/[0-9]/.test(otpText) || otpText === undefined) await this._mounted && this.setState({ otpValue: otp });
    return true
  };

  checkValidityOtp = async () => {
    let a = true;

    document.querySelectorAll('.otp-input-style').forEach((el) => {
      if (!el.checkValidity()) a = false
    });

    if (!a) addMessage('.otp-style', "Необходимо заполнить поля");
    if (!a || this.props.state.load) return false;

    setLoadStatus(this.props.load, true);
    await this.apiService.getResource('api/registration/verifySmsCode', 'POST', { 'verifyCode': this.state.otpValue, 'ticket': sessionStorage.getItem('regTicketId'), domain: window.location.hostname })
      .then(async (res) => {
        // console.log(res);
        if (!res.userId) {
          addMessage('.otp-label', res.errorMessage);
        } else {
          this.setState({
            isPhoneNumberValid: true,
            validPhoneNumber: this.state.phoneNumber,
            popupOpened: false,
            otpValue: '',
            time: null,
          })
        }
        return true
      })
    setLoadStatus(this.props.load, false);
  };

  getSms = async () => await this.apiService.getResource('api/registration/sendSmsToVerifyCode', 'POST', { 'phoneNumber': this.state.phoneNumber }, true)

  checkUsageNumber = async () => {
    if (this.props.state.load) return false

    try {
      const response = await this.apiService.getResource(`api/registration/checkPhoneNumber?phoneNumber=${this.state.phoneNumber}`, 'GET', true)
      if (response.isActive) {
        addMessage('#phone-number', 'Номер уже зарегистрирован', true);
      } else {
        await setLoadStatus(this.props.load, true);
        await this.getSms()
          .then(async (res) => {
            if (!res.success) {
              addMessage('#phone-number', res.message, true);
            } else {
              sessionStorage.setItem('regTicketId', res.ticketId);
              await this._mounted && this.setState({ time: res.timeout, popupOpened: true });
              this.timer(this);
            }
          })

        await setLoadStatus(this.props.load, false);
      }
    } catch (err) {
      return console.error(err);
    }
  }

  timer = (arg) => {
    this._mounted && arg.setState((state) => {
      state.canGet = false;
    });
    const span1 = document.getElementById('timer-span-1');
    const span2 = document.getElementById('timer-span-2');
    let seconds = this.state.time;
    span1.innerHTML = 'Отправить код повторно можно через ';
    span2.innerHTML = seconds + ' сек.';

    const timerFunc = () => {
      seconds--;
      span2.innerHTML = seconds + ' сек.';
      if (seconds > 0) {
        return this.timerId = setTimeout(timerFunc, 1000);
      } else {
        this._mounted && arg.setState((state) => {
          state.canGet = true;
        });
        span1.innerHTML = '';
        span2.innerHTML = 'Получить новый код';
      }
    };

    this.timerId = setTimeout(timerFunc, 1000)
  };

  render() {
    const { files, agree, fullName, phoneNumber, email, clearFiles, type, isPhoneNumberValid, popupOpened, validPhoneNumber,
      birthDay, birthPlace, passportNumber, passportDate, passportCode, passportIssue, address } = this.state;
    const isPhoneNumberConfirmed = validPhoneNumber === phoneNumber && phoneNumber !== '';


    return (
      <ValidFuncConsumer>
        {({ checkValidity }) =>
          <form noValidate id="reg-step-third-form" className="reg-step-form" onSubmit={this.onSubmit}>
            {type !== 'Freelance' ? <>
              <h2>Данные пользователя</h2>

              <div className="agree-with-container" onClick={async () => {
                await this.setState({ agree: !this.state.agree, changed: true })
                !this.state.agree && this._mounted && this.setMask();
              }}>
                <div className={agree ? "app-checkbox active" : 'app-checkbox'}></div>
                <div className="agree-with">Совпадают с данными руководителя</div>
              </div>

              {!agree ? <>
                <div className="row-of-inputs reverse-inputs-length without-max-width">
                  <MyInput title="ФИО" value={fullName} onInput={(e) => this.setState({ fullName: e.target.value, changed: true, })} placeholder={'Иванов Иван Иванович'} required />
                  <MyInput title="Телефон" id='phone-number' value={phoneNumber} onInput={(e) => this.setState({ phoneNumber: phoneNumberCutter(e.target.value), changed: true, isPhoneNumberValid: document.getElementById('phone-number').checkValidity() })} pattern="^(\+7|7|8)[0-9]{3}[0-9]{3}[0-9]{2}[0-9]{2}$" placeholder="7ХХХХХХХХХХ" messagetext="Введите номер в формате 7ХХХХХХХХХХ" required />
                  <button type="button" className={`primary-btn check-phone-number-btn app-btn ${!isPhoneNumberValid || isPhoneNumberConfirmed ? 'disabled' : ''}`} onClick={this.checkUsageNumber} disabled={!isPhoneNumberValid || isPhoneNumberConfirmed}>{isPhoneNumberConfirmed ? 'Номер подтвержден' : 'Подтвердить по SMS'}</button>

                  {popupOpened ? <Popup
                    popupName={'.third-step-popup'}
                    className={'third-step-popup'}
                    popupClose={() => this.setState({ popupOpened: false })}
                    withCloseBtn
                    content={(<>
                      <label className="otp-label">
                        <h1>Подтверждение номера</h1>
                        <div className="sended-sms additional-notice">
                          Код отправлен на номер <span className="reg-tel">{phoneNumber}</span>
                        </div>
                        <MyOtp
                          onChange={otp => { this.onChangeOtpInput(otp) }}
                          onFinish={this.checkValidityOtp}
                          numInputs={4}
                          value={this.state.otpValue}
                          containerStyle="otp-style"
                          inputStyle="otp-input-style"
                          focusStyle={{ "borderBottom": "solid #828282 2px" }}
                          doneStyle={{ "borderBottom": "solid #828282 2px" }}
                          shouldAutoFocus={true}
                        />
                      </label>

                      <button className={`primary-btn app-btn otp-btn`} onClick={() => this.checkValidityOtp()}>Продолжить</button>

                      <div className="additional-notice timer-span-wrap" onClick={async () => {
                        if (this.state.canGet) {
                          this._mounted && this.setState({ otpValue: '' });
                          document.querySelectorAll('.otp-input-style')
                            .forEach((el) => {
                              el.classList.remove("otp-input-style-past");
                            });
                          const firstOtpInput = document.querySelector('.otp-input-style:first-of-type');
                          firstOtpInput.focus();
                          await this.getSms();
                          this.timer(this);
                        }
                      }}>
                        <span id="timer-span-1">Отправить код повторно можно через </span><span id="timer-span-2"></span>
                      </div>

                      <button type="button" className="reg-link fs-14" onClick={() => {
                        this.regStateChanger(0);
                        this._mounted && this.setState({ otpValue: '' });
                      }}>Изменить номер телефона</button>
                    </>)}
                  /> : null}
                </div>

                <MyInput title="Email" value={email} placeholder={'mailbox@domain.ru'} pattern="^[^@ ]+@[^@ ]+$" onInput={(e) => this.setState({ email: e.target.value, changed: true })} required />

                <h3>Паспортные данные</h3>

                <div className="row-of-inputs without-max-width">
                  <MyInput title="Дата рождения" id="birthDay" minLength="10" maxLength="12" pattern="^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$" placeholder="XX.XX.XXXX" required value={birthDay} onInput={(e) => this.setState({ birthDay: e.target.value, changed: true })} messagetext="Введите в формате XX.XX.XXXX" />
                  <MyInput title="Место рождения" id="birthPlace" placeholder="Точно как в паспорте" value={birthPlace} onInput={(e) => this.setState({ birthPlace: e.target.value, changed: true })} required />
                </div>

                <div className="row-of-inputs without-max-width">
                  <MyInput title="Серия и номер паспорта" id="passportNumber" pattern="^\d{4} \d{6}$" placeholder="XXXX XXXXXX" messagetext="Введите в формате XXXX XXXXXX" value={passportNumber} onInput={(e) => this.setState({ passportNumber: e.target.value, changed: true })} required />
                  <MyInput title="Дата выдачи паспорта" id="passportDate" pattern="^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$" placeholder="XX.XX.XXXX" messagetext="Введите в формате XX.XX.XXXX" value={passportDate} onInput={(e) => this.setState({ passportDate: e.target.value, changed: true })} required />
                  <MyInput title="Код подразделения" id="passportCode" pattern="^\d{3}-\d{3}$" placeholder="XXX-XXX" messagetext="Введите в формате XXX-XXX" value={passportCode} onInput={(e) => this.setState({ passportCode: e.target.value, changed: true })} required />
                </div>

                <div className="row-of-inputs without-max-width">
                  <MyInput title="Кем выдан паспорт" id="passportIssue" placeholder="Точно как в паспорте" value={passportIssue} onInput={(e) => this.setState({ passportIssue: e.target.value, changed: true })} required />
                </div>

                <div className="row-of-inputs without-max-width">
                  {/* <MyInput title="Адрес проживания" id="address" placeholder="Город, адрес" value={address} onInput={(e) => this.setState({ address: e.target.value, changed: true })} required /> */}

                  <label className="app-label">
                    <span className="input-title">Адрес проживания</span>
                    {this.loaded && <AddressSuggestions
                      token={this.dadataToken}
                      defaultQuery={address}
                      onChange={(e) => {
                        this.setState({ address: `${e.data.postal_code}, ${e.value}`, postalCode: (e.data.postal_code || ''), changed: true })
                      }}
                      filterLocations={[{ country: "*" }]}
                      selectOnBlur={true}
                      inputProps={{
                        id: 'address',
                        placeholder: 'Город, адрес',
                        required: true,
                        onChange: this.handleAddressChange,
                      }}
                    />
                    }
                  </label>
                </div>

                <h3 className="dz-header">Добавьте сканы паспорта</h3>
                <h4 className="additional-notice">Размером до 10 Мб</h4>

                <div className="row-of-inputs my-dropzone-row">
                  {this.filesDesc.map((desc, index) => (
                    <div className="my-dz" key={index}>
                      <MyDropzone clearFiles={clearFiles} setClearStatus={this.setClearStatus} dropzoneInputId={index} photo={files[index]} filesStateChanger={this.filesStateChanger} />
                      <div className="my-dz-text">{desc}</div>
                    </div>
                  ))}

                  {/* <div className="my-dz">
                    <MyDropzone clearFiles={clearFiles} setClearStatus={this.setClearStatus} dropzoneInputId={3} photo={files[3]} filesStateChanger={this.filesStateChanger}/>
                    <div className="my-dz-text">Фото с паспортом</div>
                  </div> */}
                </div>
              </> : null}
            </> : <h2>Недоступно для фрилансеров</h2>}

            <div className="btns-container">
              <Link to={`/registration/step-2`} className="secondary-btn app-btn">Назад</Link>

              <button type="button" id="app-btn" className="primary-btn app-btn" onClick={() => !agree && type !== 'Freelance' ? this.toTheNextStep(checkValidity) : this.skipStage()}>Продолжить</button>
            </div>
          </form>
        }
      </ValidFuncConsumer>
    )
  }
};

const mapStateToProps = (state) => {
  return {
    state: state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    load: (bool) => {
      return dispatch({ type: 'LOAD_CHANGE', payload: bool })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegStepThird);