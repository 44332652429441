import React, { Component } from "react";
import { Link } from "react-router-dom";
import IMask from 'imask';
import MyInput from "../../../utils/input";

import { ValidFuncConsumer } from "../../../scripts/validFuncContext";
import ApiService from "../../../services/apiService";

import { setLoadStatus, addMessage, getFromBase64, postBase64, scrollPageTo } from "../../../scripts/functions";
import { connect } from "react-redux";
import history from "../../../scripts/history";
import MyDropzone from "../../../utils/dropzone";
import { AddressSuggestions } from 'react-dadata';
import "react-dadata/dist/react-dadata.css";
import {DADATA} from "../../../scripts/indexphp"
import {IS_FREELANCE} from "../../../scripts/indexphp.js"

class RegStepSecond extends Component {

  state = {
    type: IS_FREELANCE ? 'Freelance' : '',
    name: '',
    phoneNumber: localStorage.getItem('phoneNumber'),
    email: '',
    birthDay: '',
    birthPlace: '',
    passportNumber: '',
    passportDate: '',
    passportCode: '',
    passportIssue: '',
    address: '',
    tmpaddr: '',
    tmpsug: '',
    postalCode: '',
    files: [null, null, null, ],
    filesFormat: {
      passport1stPageFormat: '',
      passportRegistrationPageFormat: '',
      passportEarlyPageFormat: '',
    },
    bik: '',
    bankName: '',
    settlementAccount: '',
    correspondentAccount: '',
    bikIsCorrect: false,
    changed: false,
    toCreate: true,
    loaded: false
  }

  filesDesc = ['Первая страница', 'Страница регистрации', '19 страница паспорта']
  _mounted = false
  apiService = new ApiService();

  async componentDidMount() {
    setLoadStatus(this.props.load, true);
    this._mounted = true;
    this.props.updateCurStep(1);

    await this.getCompanyInfo()

    if (this.props.state.doneStep >= 2) {
      await this.getBankInfo();
      await this.checkBik(undefined, false)
      await this.getUserInfo()
    }

    this.loaded = true;
    setLoadStatus(this.props.load, false);
  };

  componentWillUnmount() {
    this._mounted = false
  }

  getCompanyInfo = async () => {
    return await this.apiService.getResource(`api/companyInfo?companyId=${localStorage.getItem('companyId')}`, "GET", {})
      .then(async (res) => {
        // console.log(res)
        if (!res || !res.success) return false
        const { type } = res.data;

        await this._mounted && this.setState({ type: type })

        if (type === "Freelance") {
          this._mounted && this.setMask();
          return false
        }
        return await this.apiService.getResource('api/companyInfo/getCompanyInfo', 'POST', { inn: res.data.inn })
      })
      .then(async (res) => {
        // console.log(res)
        if (!res || !res.success) return false
        await this._mounted && this.setState({ name: res.data.ceo_name || res.data.name.replaceAll('ИП ', '') })
        return true
      })
  }

  getBankInfo = async () => {
    return await this.apiService.getResource(`api/bankRequisite?companyId=${localStorage.getItem('companyId')}`, "GET", {})
      .then(async (res) => {
        // console.log(res)

        if (res && res.success && this._mounted) {
          await this.setState({
            bik: res.data.bik || '',
            bikIsCorrect: res.data.bik,
            settlementAccount: res.data.settlementAccount || '',
            correspondentAccount: res.data.correspondentAccount || ''
          })
        }
        return true
      })
  }

  checkBik = async (checkValidity, getCompanyInfo) => {
    if (checkValidity && checkValidity('reg-step-second-form-bank')) return false
    if (this.state.type === "Freelance") return false

    if (getCompanyInfo) await setLoadStatus(this.props.load, true);
    return await this.apiService.getResource('api/bankRequisite/getBankInfo', 'POST', { bik: this.state.bik.replaceAll(' ', '') })
      .then(async (res) => {
        // console.log(res)
        if (res.success) {
          await this._mounted && this.setState({
            bikIsCorrect: res.data.bik,
            bankName: res.data.name.replaceAll('&quot;', '"'),
            correspondentAccount: res.data.correspondent_account,
          })
          if (getCompanyInfo) await this.getCompanyInfo();
          this._mounted && this.setMask();
        }
        else {
          this._mounted && addMessage('#bik', res.message, true)
          this._mounted && scrollPageTo(document.querySelector('#bik'), res.message, true)
        }
        if (getCompanyInfo) await setLoadStatus(this.props.load, false);
        return true
      })
  }

  getUserInfo = async () => {
    return await this.apiService.getResource('api/employee/getEmployees', 'POST', { companyId: localStorage.getItem('companyId') })
      .then(async (res) => {
        // console.log(res)
        if (!res || !res.data.length) return false
        let userId = res.data.filter(u => u.role === 'CEO')[0];
        if (userId) userId = userId.userId
        return userId ? await this.apiService.getResource(`api/employee?userId=${userId}`, 'GET', {}) : false
      })
      .then(async (result) => {
        // console.log(result)
        if (!result || !result.success || !result.data) return false

        this._mounted && await this.setState({
          ...result.data,
          userId: result.data.userId,
          toCreate: false
        })

        this._mounted && this.updateMasks()

        const transformFiles = (files = [], names = [], formats = []) => files.forEach((f, i) => this.getBase64(f, names[i], formats[i]))
        transformFiles(
          [result.data.passport1stPage, result.data.passportRegistrationPage, result.data.passportEarlyPage, ],
          ['passport1stPage', 'passportRegistrationPage', 'passportEarlyPage', ],
          [result.data['passport1stPageFormat'], result.data['passportRegistrationPageFormat'], result.data['passportEarlyPageFormat'], ]
        )
        return true
      })
  }

  setMask = () => {
    this.birthDay = IMask(document.getElementById('birthDay'), { mask: '00.00.0000', lazy: true });
    this.passportNumber = IMask(document.getElementById('passportNumber'), { mask: '0000 000000', lazy: true });
    this.passportDate = IMask(document.getElementById('passportDate'), { mask: '00.00.0000', lazy: true });
    this.passportCode = IMask(document.getElementById('passportCode'), { mask: '000-000', lazy: true });
    if (this.state.type !== "Freelance") this.settlementAccount = IMask(document.getElementById('settlementAccount'), { mask: '0000 0000 0000 0000 0000', lazy: true });
  };

  updateMasks = () => {
    this.birthDay.updateValue()
    this.passportNumber.updateValue()
    this.passportDate.updateValue()
    this.passportCode.updateValue()
    if (this.state.type !== "Freelance") this.settlementAccount.updateValue()
  }

  setClearStatus = () => {
    this._mounted && this.setState({ clearFiles: false });
    this.birthDay.value = '';
    this.passportNumber.value = '';
    this.passportDate.value = '';
    this.passportCode.value = '';
    if (this.state.type !== "Freelance") this.settlementAccount.value = '';
  };

  filesStateChanger = (files, fileNumber) => {
    this._mounted && this.setState((state) => {
      state.files[fileNumber] = files;
      state.changed = true
      return state
    })
  };

  getBase64 = (imageBase64, filename, type) => {
    if (!imageBase64) return false
    const ruNamesSet = {
      passport1stPage: 'Первая страница',
      passportRegistrationPage: 'Страница регистрации',
      passportEarlyPage: 'Ранее выданные паспорта',
      // photo: 'Фото с паспортом',
    }
    const namesId = {
      passport1stPage: 0,
      passportRegistrationPage: 1,
      passportEarlyPage: 2,
      // photo: 3,
    }

    const file = getFromBase64(imageBase64, filename, type, ruNamesSet)

    this._mounted && this.setState((state) => {
      state.files[namesId[filename]] = [file]
      return state
    })
  };

  onload = (file, name) => {
    return this.setState((state) => {
      state.filesFormat[name + "Format"] = file[0].type;
      return state
    })
  }

  onSubmit = (e) => {
    e.preventDefault();
  };

  toTheNextStep = async (checkValidity) => {
    if (this.props.state.load) {
      return false
    }

    if (!this.checkCeoData()) return false

    const isFreelance = this.state.type === 'Freelance';

    if (!this.state.changed && !this.state.toCreate) {
      this.props.updateDoneStep(isFreelance ? 3 : 2);
      history.push(`/registration/step-${isFreelance ? 4 : 3}`)
      return false
    }
    await setLoadStatus(this.props.load, true);

    const a = isFreelance || await this.sendBankData(checkValidity);
    if (!a) return false
    const b = await this.sendСeoData(checkValidity);
    if (!b) return false

    if (isFreelance) await this.apiService.getResource('api/registration/skipStage', "POST", { companyId: localStorage.getItem('companyId'), stage: isFreelance ? 4 : 3 })
    this.props.updateDoneStep(isFreelance ? 3 : 2);
    history.push(`/registration/step-${isFreelance ? 4 : 3}`)
    return true
  }

  sendBankData = async (checkValidity) => {
    if (checkValidity('reg-step-second-form-bank')) {
      return false
    }
    return await this.apiService.getResource('api/bankRequisite', this.state.toCreate ? "POST" : 'PUT', {
      companyId: localStorage.getItem('companyId'),
      bik: this.state.bikIsCorrect,
      correspondentAccount: this.state.correspondentAccount,
      settlementAccount: this.state.settlementAccount,
    })
      .then((res) => {
        // console.log(res)
        if (res && !res.success) {
          addMessage(`#${res.errorField}`, res.message, true)
          scrollPageTo(document.querySelector(`#${res.errorField}`))
          return false
        }
        return res && res.success
      })
  }

  checkCeoData = async () => {
    let {address, postalCode} = this.state
    if (postalCode && address) {
      address=`${postalCode}, ${address}`
    }
    const data = {
      role: 2,
      companyId: localStorage.getItem('companyId'),
      name: this.state.name,
      phoneNumber: this.state.phoneNumber,
      email: this.state.email,
      birthDay: this.state.birthDay,
      birthPlace: this.state.birthPlace,
      passportNumber: this.state.passportNumber,
      passportDate: this.state.passportDate,
      passportCode: this.state.passportCode,
      passportIssue: this.state.passportIssue,
      address,
    }

    const files = this.state.files;
    const names = [
      'passport1stPage',
      'passportRegistrationPage',
      'passportEarlyPage',
    ];

    let checkFilesValid = true;
    const filesLength = files.map(file => file !== null ? Object.keys(file).length : false);

    files.forEach((f, i) => {
      if (!filesLength[i]) {
        checkFilesValid = false
      }
    })

    // Не требовать фото у фрилансеров
    if (this.state.type !== 'Freelance') {
      if (checkFilesValid && files.length) {
        for (let i = 0; i < files.length; i++) {
          data[names[i]] = await postBase64(files[i], names[i], this.onload);
          data[names[i] + "Format"] = this.state.filesFormat[names[i] + "Format"]
        }
      } else {
        this._mounted && addMessage('.my-dropzone-row', 'Необходимо добавить все фото');
        return false
      }
    }

    if (!data.address) {
      return false
    }

    return data
  }

  sendСeoData = async (checkValidity) => {
    if (checkValidity('reg-step-second-form-seo')) {
      return false
    }

    const data = await this.checkCeoData();

    if (!data) {
      console.log(data)
      return false
    }

    return await this.apiService.getResource('api/employee', this.state.toCreate ? 'POST' : 'PUT', { ...data, userId: this.state.userId })
      .then(async (res) => {
        // console.log(res)
        if (res && !res.success) {
          if (res.errorField) {
            addMessage(`#${res.errorField}`, res.message, true)
            scrollPageTo(document.querySelector(`#${res.errorField}`))
          }
          return false
        }
        return res && res.success
      })
  }

  handleAddressChange = (event) => {
//console.log('handleAddressChange', event.target.value)
//    return
    const tmpaddr=event.target.value || ''
    let newsta={tmpaddr}
    if (this.state.address!==tmpaddr) {
      newsta={
        tmpaddr, 
        address: '', 
        postalCode: ''
      }
    }
    this.setState( newsta )
  }
  dadaSet = (e) => {
    const {value, data}=e
//console.log('dadaSet', value, data)
    const tmpsug={value, data}
    let newsta={ tmpsug }
    if (data.fias_level>=7 && data.house) {
      newsta={
        tmpsug,
        tmpaddr: value,
        address: value, 
        postalCode: data.postal_code
      }
    }
    this.setState( newsta ) 
  }
  dadaErr = () => {
      const {tmpaddr , address, tmpsug}=this.state
      let m=''
      if (tmpaddr && !address) {
          m=tmpsug ?  'уточните адрес до дома и выберите из выпадающего списка' : 'выберите адрес из выпадающего списка'
      }
      return m
  }  

  render() {
    const { files, bik, bikIsCorrect, settlementAccount, name, phoneNumber, email, clearFiles, type,
      birthDay, birthPlace, passportNumber, passportDate, passportCode, passportIssue } = this.state;

    const dadaErr=this.dadaErr

    return (
      <ValidFuncConsumer>
        {({ checkValidity, showValid }) => {
          return (
            <>
              {type !== "Freelance" ? <form id="reg-step-second-form-bank" className="reg-step-form" onSubmit={this.onSubmit}>
                <h2>Банковские реквизиты</h2>


                <div className="row-of-inputs without-media">
                  <MyInput title="БИК" id="bik" value={bik} onInput={(e) => this._mounted && this.setState({ bik: e.target.value, changed: true })} required />
                  {bikIsCorrect ? <button type="button" className="primary-text return-to-initial" onClick={() => this.setState({ bikIsCorrect: false, bik: '', settlementAccount: '' })}>Изменить</button> : null}
                </div>
                {bikIsCorrect ? <>
                  <div className="info-block">
                    <div className="title-div">{this.state.bankName}</div>
                    <div className="text-div">К/сч {this.state.correspondentAccount} </div>
                  </div>

                  <MyInput title="Расчетный счет" id='settlementAccount' placeholder="XXXX XXXX XXXX XXXX XXXX" value={settlementAccount} onInput={(e) => this._mounted && this.setState({ settlementAccount: e.target.value, changed: true })} required />
                </> : null}

              </form> : null}
              <form id="reg-step-second-form-seo" className="reg-step-form" onSubmit={this.onSubmit}>
                {bikIsCorrect || type === "Freelance" ? <>
                  <h2>Данные руководителя</h2>
                  <div className="row-of-inputs reverse-inputs-length without-max-width">
                    <MyInput title="ФИО" value={name} onInput={(e) => this._mounted && this.setState({ name: e.target.value, changed: true })} disabled={type !== "Freelance"} readOnly={type !== "Freelance"} required />
                    <MyInput title="Телефон" value={phoneNumber} disabled readOnly required />
                  </div>

                  <div className="row-of-inputs reverse-inputs-length without-max-width">
                    <MyInput title="Email" value={email} placeholder={'mailbox@domain.ru'} pattern="^[^@ ]+@[^@ ]+$" onInput={(e) => this._mounted && this.setState({ email: e.target.value, changed: true })} required />
                  </div>

                  <h3>Паспортные данные</h3>

                  <div className="row-of-inputs without-max-width">
                    <MyInput title="Дата рождения" id="birthDay" minLength="10" maxLength="12" pattern="^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$" placeholder="XX.XX.XXXX" required value={birthDay} onInput={(e) => this._mounted && this.setState({ birthDay: e.target.value, changed: true })} messagetext="Введите в формате XX.XX.XXXX" />
                    <MyInput title="Место рождения" id="birthPlace" placeholder="Точно как в паспорте" value={birthPlace} onInput={(e) => this._mounted && this.setState({ birthPlace: e.target.value, changed: true })} required />
                  </div>

                  <div className="row-of-inputs without-max-width">
                    <MyInput title="Серия и номер паспорта" id="passportNumber" pattern="^\d{4} \d{6}$" placeholder="XXXX XXXXXX" messagetext="В формате XXXX XXXXXX" value={passportNumber} onInput={(e) => this._mounted && this.setState({ passportNumber: e.target.value, changed: true })} required />
                    <MyInput title="Дата выдачи паспорта" id="passportDate" pattern="^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$" placeholder="XX.XX.XXXX" messagetext="Введите в формате XX.XX.XXXX" value={passportDate} onInput={(e) => this._mounted && this.setState({ passportDate: e.target.value, changed: true })} required />
                    <MyInput title="Код подразделения" id="passportCode" pattern="^\d{3}-\d{3}$" placeholder="XXX-XXX" messagetext="Введите в формате XXX-XXX" value={passportCode} onInput={(e) => this._mounted && this.setState({ passportCode: e.target.value, changed: true })} required />
                  </div>

                  <div className="row-of-inputs without-max-width">
                    <MyInput title="Кем выдан паспорт" id="passportIssue" placeholder="Точно как в паспорте" value={passportIssue} onInput={(e) => this._mounted && this.setState({ passportIssue: e.target.value, changed: true })} required />
                  </div>

                  <div className="row-of-inputs without-max-width">
                  {this.loaded && (

                    <label className={`app-label ${dadaErr() ? 'has-error' : ''}`} messagetext={dadaErr()}>
                      <span className="input-title">Адрес проживания</span>
                      <AddressSuggestions
                        token={DADATA}
                        onChange={this.dadaSet}
                        inputProps={{
                          id: 'address',
                          placeholder: 'Город, адрес',
                          required: true,
                          onInput: this.handleAddressChange,
                        }}
                      />
                    </label>
                  )}
                  </div>

                  {type !== 'Freelance' && <>
                    <h3 className="dz-header">Добавьте сканы паспорта</h3>
                    <h4 className="additional-notice">Размером до 10 Мб</h4>

                    <div className="row-of-inputs my-dropzone-row">
                      {this.filesDesc.map((desc, index) => (
                        <div className="my-dz" key={index}>
                          <MyDropzone clearFiles={clearFiles} setClearStatus={this.setClearStatus} dropzoneInputId={index} photo={files[index]} filesStateChanger={this.filesStateChanger} />
                          <div className="my-dz-text">{desc}</div>
                        </div>
                      ))}
                    </div>
                  </>}
                </> : null}

                <div className="btns-container">
                  <Link to={`/registration/step-1`} className="secondary-btn app-btn">Назад</Link>
                  <button type="button" id="app-btn" className="primary-btn app-btn" onClick={() => !bikIsCorrect && type !== "Freelance" ? this.checkBik(checkValidity, true) : this.toTheNextStep(checkValidity).then(() => setLoadStatus(this.props.load, false))}>Продолжить</button>
                </div>
              </form>
            </>
          )
        }}
      </ValidFuncConsumer>
    )
  }
};

const mapStateToProps = (state) => {
  return {
    state: state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    load: (bool) => {
      return dispatch({ type: 'LOAD_CHANGE', payload: bool })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegStepSecond);